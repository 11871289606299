import React from 'react';
import { Layout, Seo } from 'components';
import { Row, Col } from "react-grid-system";
import ProductHero from "components/product-hero";
import SlideContent from "components/slide-content";
import { TypeB } from "components/callouts";
import productData from "components/product-data";
import Slides from "components/product-data/slides";

import imgEphedrineSulfate from "images/products/img-prevduo-box-and-syringe.png";
import bivalirudinBoxImg from "images/home/bivalirudin-box-and-vial.png";
import dexmedBoxImg from "images/home/dexmed-pole-image.png";
import ephedrineBoxImg from "images/home/ephedrine-sulfate-injection-bottle.svg";
import vasostrictBoxImg from "images/home/vasostrict-bottles.svg";

import './products.scss';

const Reference = (
    <>
        <p>
            <strong>References: 1.</strong> PREVDUO<sup>®</sup>. Prescribing Information. Par Pharmaceutical, Inc. <strong>2.</strong> Data on file. DOF-PRVD-01. Par Pharmaceutical, Inc.; July 20, 2023. <strong>3.</strong> The United States Pharmacopeial Convention. USP compounding standards and beyond-use dates. https://go.usp.org/2022_Revisions_795_797. Accessed November 8, 2023. <strong>4.</strong> Data on file. DOF-EH-01. Par Pharmaceutical, Inc.; July 31, 2023. <strong>5.</strong> Data on file. DOF-DX-01. Par Pharmaceutical, Inc.; July 27, 2023. <strong>6.</strong> Data on file. DOF-VS-02. Par Pharmaceutical, Inc.; September 21, 2023. <strong>7.</strong> Bivalirudin Injection. Prescribing Information. Par Pharmaceutical, Inc. <strong>8.</strong> Healthcare Distribution Alliance. Standard pharmaceutical product and medical device information: Bivalirudin Injection. July 10, 2023.
        </p>
    </>
)

function PrevduoInjectionPage({ pageContext }) {
    
    return (
        <Layout
            product="prevduo"
            pageClass="products page-prevduo"
            reference={Reference}
            breadcrumbData={pageContext.breadcrumb}
        >
            <Seo pageTitle="PREVDUO® (neostigmine methylsulfate and glycopyrrolate) Injection" pageDescription="PREVDUO® (neostigmine methylsulfate and glycopyrrolate) Injection available in prefilled syringes" />

            <section className="first">
                <div className="centered-content">
                    <ProductHero
                        productTitle="PREVDUO<sup>®</sup> <br class='show-desktop' /> (neostigmine methylsulfate and glycopyrrolate) Injection"
                        productSubTitle="Available in prefilled syringes"
                        productUnitsOne="Neostigmine methylsulfate: <br class='show-mobile' />3 mg/3 mL (1 mg/mL)"
                        productUnitsTwo="Glycopyrrolate: <br class='show-mobile' />0.6 mg/3 mL (0.2 mg/mL)"
                        productImg={imgEphedrineSulfate}
                        productImgAlt="Prevduo carton and prefilled syringe"
                        className="prd-prevduo"
                    ></ProductHero>

                    <div className="hero-inner__footnote">
                        <p>PREVDUO<sup>®</sup> is a registered trademark of Slayback Pharma LLC.</p>
                        <p>PREVDUO<sup>®</sup> is an alternative to your compounded neostigmine and glycopyrrolate prefilled syringes and/or any 503B or FDA-approved neostigmine or glycopyrrolate prefilled syringes and is not intended to replace other reversal neuromuscular blocking agents (NMBAs). Please refer to the Prescribing Information for labeled indication.</p>
                    </div>
                </div>
            </section>

            <section className="gray-bg">
                <div className="centered-content">
                    <Row align="start" justify="between">
                        <Col xl={4.75} lg={5} xs={12}>
                            <SlideContent
                                subTitle="The benefits of ready-to-use (RTU) in the <br class='show-desktop' />following concentration<sup>1</sup>"
                                details="PREVDUO<sup>®</sup> prefilled syringes do not require compounding, diluting, mixing, or transferring and provide extended dating compared with compounded products.<sup>1-3</sup>* PREVDUO<sup>®</sup> prefilled syringes are preservative-free and compatible with most automated dispensing machines."
                            />
                        </Col>
                        <Col lg={6} xs={12}>
                            <Slides data={productData.prevduo} />
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="links-row">
                <div className="centered-content">
                    <Row>
                        <Col xs={12}>
                            <a
                                href="https://www.prevduo.com/workflow-scenarios"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="external-link"
                                style={{ display: 'inline-block' }}>
                                Pharmacy Workflow Preparation
                            </a>

                            <p>
                                <a
                                    href="https://d2k7f200kjqc2w.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/43d073ca-04a6-4a34-9cda-8c61f9b9ef3f/43d073ca-04a6-4a34-9cda-8c61f9b9ef3f_source__v.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="pdf-link">
                                    PREVDUO<sup>®</sup> Information Document
                                </a>
                            </p>

                            <p>
                                <a
                                    href="https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/dea114af-fa09-4d47-b4e4-b79b0f0614f0/dea114af-fa09-4d47-b4e4-b79b0f0614f0_source__v.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="pdf-link">
                                    Market Research Infographic
                                </a>
                            </p>

                            <p>
                                <a
                                    href="https://d1skd172ik98el.cloudfront.net/48a33315-f594-4269-8043-8853d10fb7bf/0f946e45-a640-42ed-a890-b795aeb86b5d/0f946e45-a640-42ed-a890-b795aeb86b5d_source__v.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="pdf-link">
                                    Dimensions Guide
                                </a>
                            </p>
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="cta-row">
                <div className="centered-content">
                    <div className="block-title">
                        <h3>Explore TruDelivery<sup>&reg;</sup> RTU portfolio</h3>
                    </div>

                    <Row justify="center">
                        <Col lg={4.25} md={6}>
                            <TypeB
                                ctaTitle="Bivalirudin Injection"
                                ctaSubTitle="Ready-to-use bottles"
                                ctaImage={bivalirudinBoxImg}
                                ctaImageAlt='Bivalirudin injection ready-to-use bottles'
                                pdfLink='https://www.parpharm.com/pdfs/catalog/sterile/v2_Bivalirudin_Injection_PI_04-2023.pdf'
                                buttonText="View Product"
                                buttonURL="/products/bivalirudin-injection"
                            />
                        </Col>

                        <Col lg={4.25} md={6}>
                            <TypeB
                                className='dexmed-img'
                                ctaTitle="Dexmedetomidine HCl Injection"
                                ctaSubTitle="Ready-to-use bottles"
                                ctaImage={dexmedBoxImg}
                                ctaImageAlt='Dexmedetomidine HCI injection ready-to-use bottles'
                                pdfLink='https://www.endodocuments.com/documents/Dexmedetomidine/Dexmedetomidine_PI.pdf'
                                buttonText="View Product"
                                buttonURL="/products/dexmedetomidine-hcl"
                                captionClass='img-caption__right'
                            />
                        </Col>

                        <Col lg={4.25} md={6}>
                            <TypeB
                                ctaTitle="Ephedrine Sulfate<br class='show-desktop' /> Injection"
                                ctaSubTitle="Premixed vials"
                                ctaImage={ephedrineBoxImg}
                                ctaImageAlt='Ephedrine sulfate injection premixed vials'
                                pdfLink='https://www.endodocuments.com/documents/Ephedrine/Ephedrine_5_PI.pdf'
                                buttonText="View Product"
                                buttonURL="/products/ephedrine-sulfate-injection"
                                captionClass='img-caption__right img-caption__rightAlt'
                                className='pb-lg-0'
                            />
                        </Col>

                        <Col lg={4.25} md={6}>
                            <TypeB
                                ctaTitle="Vasostrict<sup>&reg;</sup><br />(Vasopressin Injection, USP)"
                                ctaSubTitle="Ready-to-use bottles"
                                ctaImage={vasostrictBoxImg}
                                ctaImageAlt='Vasostrict ready-to-use bottles'
                                pdfLink='https://www.endodocuments.com/documents/Vasostrict/Vasostrict_PI.pdf'
                                buttonText="View Product"
                                buttonURL="/products/vasostrict-injection"
                                captionClass='img-caption__right'
                                className='pb-lg-0'
                            />
                        </Col>
                    </Row>
                </div>
            </section>

            <section className="block-footnotes">
                <div className="centered-content">
                    <div className="footnotes">
                        <div className="footnotes-wrap">
                            <span className='symbol'>*</span>
                            <p className="footnotes-symbols">For Category 3 controlled sterile preparations (CSP), the maximum beyond-use date (BUD) is 90 days at controlled room temperature (CRT), 120 days in refrigerator, and 180 days in freezer<sup>3</sup> versus shelf life of 2 years at CRT for Ephedrine, PREVDUO<sup>®</sup>, and Dexmedetomidine<sup>2,4,5</sup>; 12 months at CRT and 2 years in refrigerator for Vasostrict<sup>®6</sup>; 72 hours at CRT and 18 months in refrigerator for Bivalirudin.<sup>7,8</sup></p>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

export default PrevduoInjectionPage;